.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: steelblue;
  box-sizing: border-box;
}
